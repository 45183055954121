import React,{ useState, useEffect} from "react";
import "./PanameraDrop.scss";
import Back from '../Back';
import { useTranslation } from 'react-i18next';
import slex from "./slex.png"
import tether from "./tether.png"
import usdt from "./usdt.png"
import top from "./top.png"
import { getSlexExchangeTaskStatus,registerSlexExchangeTask } from "../../http";
import loading from "./loading.png"

function Countdown({ endDate }) {
    
    const calculateTimeLeft = () => {
        const difference = +new Date(endDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    return (
        <div className="countdown_listing">
            {Object.keys(timeLeft).length > 0 ? (
                Object.keys(timeLeft).map((interval) => (
                    <div key={interval} className="countdown-item_exchange">
                        <div className="countdown-time">{timeLeft[interval]}</div>
                        <div className="countdown-label">{interval}</div>
                    </div>
                ))
            ) : (
                <></>
            )}
        </div>
    );
}

function TableWithButton({ data }) {
  const { t } = useTranslation();


  return (
    <>
      {data.map((item) => (
        <div className="info_container" key={item.id}>
          <div style={{ width: "3%" }}>{item.id}</div>
          <div className="image_container">
            <img src={item.icon} alt={item.text} />
          </div>
          <div className="des_panamera">{item.text}</div>
         
        </div>
      ))}
    </>
  );
}

const SlexListing = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState("")

  const fetchData = async () =>{
    const  data = await getSlexExchangeTaskStatus()
    setStatus(data.status)
  }
  useEffect(() => {
    fetchData()

    console.log(status)
  }, []);


  const data = [
    { id: 1, text: "Register on SLEX exchange",  name: t("inviteFriend"), icon:slex },
    { id: 2, text: "Buy USDT SIMPLY with ", name: t("shareStory"), icon:tether },
    { id: 3, text: "Buy NIKI with USDT", name: t("participateInContest"), icon : usdt},
  ];
  
  return (
    <div className="outer-container_listing">
      <div className="container_glow_panamera1">
        <Back />

        <img src={top} className="image_top"></img>    

        <TableWithButton data={data} />
        <div className="title_timer">Listing timer:</div>
        <Countdown endDate={"2024-12-19T11:00:00"} />
        <button className="custom-button">
          <span className="button-label" onClick={()=>registerSlexExchangeTask()}>
            
            {status === "done" ? "Buy $NIKI" : "Register"}
             

            {status ==="pending"&&
              <div className="promo-button pending">
                Checking
                <img className="arrow-icon" src={loading} />

              </div>
            }
            
          </span>
        </button>
      </div>

    </div>
  );
};

export default SlexListing;
